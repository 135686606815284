<i18n>
{
  "de": {
    "listTitles": {
      "id": "ID",
      "name": "Name",
      "email": "E-Mail",
      "company": "Firma"
    },
    "pageTitle": "Registrierte Benutzer",
    "noUsers": "Keine Benutzer",
    "addUser": "Benutzer hinzufügen",
    "deleteConfirmation": "Wollen Sie {first_name} {last_name} ({email}) wirklich löschen?",
    "toggleAdminOffButtonLabel": "Admin-Rechte entziehen",
    "toggleAdminOffConfirmation": "Wollen Sie {name} wirklich die Admin-Rechte entziehen?",
    "toggleAdminOnButtonLabel": "Admin-Rechte geben",
    "toggleAdminOnConfirmation": "Wollen Sie {name} wirklich Admin-Rechte geben?"
  }
}
</i18n>

<template>
  <MainLayout :ready="allUsersReady" class="admin-users-page">
    <template #default>
      <h1>{{ $t('pageTitle') }}</h1>
      <ButtonWrapper>
        <router-link class="button" :to="{ name: 'adminAddUser' }">{{ $t('addUser') }}</router-link>
      </ButtonWrapper>

      <DetailList v-if="allUsers.length > 0" has-header class="users-list">
        <template #header>
          <span>{{ $t('listTitles.id') }}</span>
          <span>{{ $t('listTitles.name') }}</span>
          <span>{{ $t('listTitles.company') }}</span>
          <span>{{ $t('listTitles.email') }}</span>
          <span class="align-right"></span>
          <span class="align-right"></span>
        </template>
        <li v-for="user in allUsers" :key="user.id" class="user-list-row">
          <router-link :to="{ name: 'adminUser', params: { user_id: user.id } }">
            <span>{{ user.id }}</span>
            <span
              ><strong>{{ user.first_name }} {{ user.last_name }}</strong></span
            >
            <span>{{ user.company_name }}</span>
            <span>{{ user.email }}</span>
            <div class="align-right"><span v-if="user.admin" class="admin-badge">Admin</span></div>
          </router-link>
          <ListButtonWrapper>
            <div v-if="loggedInUserEmail !== user.email">
              <ListDeleteButton @click="onDelete(user)" />
              <button v-if="user.admin" class="button" type="submit" @click.prevent="onToggleAdminOff(user)">
                {{ $t('toggleAdminOffButtonLabel') }}
              </button>
              <button v-if="!user.admin" class="button" type="submit" @click.prevent="onToggleAdminOn(user)">
                {{ $t('toggleAdminOnButtonLabel') }}
              </button>
            </div>
          </ListButtonWrapper>
        </li>
      </DetailList>

      <p v-else>{{ $t('noUsers') }}</p>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'

export default {
  name: 'adminUsers',

  mixins: [
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
  ],

  components: {
    ListButtonWrapper,
    ListDeleteButton,
    MainLayout,
    ButtonWrapper,
    DetailList,
  },

  computed: {
    ...mapGetters({
      loggedInUserEmail: 'account/email',
    }),

    hasAdmins() {
      return Boolean((this.allUsers || []).find((u) => u.admin))
    },
  },

  methods: {
    ...mapActions({
      deleteUser: 'users/deleteUser',
      addAdminRoleToUser: 'users/addAdminRoleToUser',
      removeAdminRoleFromUser: 'users/removeAdminRoleFromUser',
    }),

    async onDelete(user) {
      const confirmationText = this.$t('deleteConfirmation', { ...user })
      if (confirm(confirmationText)) {
        try {
          this.error = null
          await this.deleteUser(user.id)
        } catch (error) {
          this.error = error
        }
      }
    },

    async onToggleAdminOff(user) {
      const confirmationText = this.$t('toggleAdminOffConfirmation', { ...user })
      if (confirm(confirmationText)) {
        try {
          this.error = null
          await this.removeAdminRoleFromUser(user.id)
        } catch (error) {
          this.error = error
        }
      }
    },

    async onToggleAdminOn(user) {
      const confirmationText = this.$t('toggleAdminOnConfirmation', { ...name })
      if (confirm(confirmationText)) {
        try {
          await this.addAdminRoleToUser(user.id)
          this.error = null
        } catch (error) {
          this.error = error
        }
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.admin-users-page {
  & .detail-list.users-list > ul > li {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 100px 240px;
  }

  & .user-list-row > a {
    display: contents;
  }

  & .user-list-row .admin-badge {
    background-color: var(--secondary-highlight-color);
    color: var(--inverted-text-color);
    padding: 0 var(--spacing-xs);
    border-radius: var(--box-radius);
    text-transform: uppercase;
    font-weight: 400;
  }
}
</style>
